@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.collection-basic-info {
  margin-bottom: 84px;
  @include breakpoint(large) {
    margin-bottom: 55px;
  }
  .Right {
    position: relative;
    padding-left: 3%;

    .edit-collection {
      position: absolute;
      cursor: pointer;
      top: 12px;
      right: -25px;
    }
    .imgSec {
      width: 100%;
      min-width: 525px;
      max-height: 500px;
      height: 100%;
    }
  }
}
.nftImage {
  width: 100%;
  border: solid 10px white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 304px;
  border-radius: 50%;
}
.nftImage.withbanner {
  position: absolute;
  top: 10%;
  right: 41%;
}
.InputTitle {
  letter-spacing: 0.26em;
  white-space: nowrap;
  color: $textColorNew;
}
.inputSec {
  position: relative;
  font-size: 13px;
  color: rgba($color: #000000, $alpha: 1);
  border: 1px solid #0000;
  border-radius: 10px;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(
        120deg,
        rgba($color: #42dace, $alpha: 0.5),
        rgba($color: #4786d6, $alpha: 0.5)
      )
      border-box;
  line-height: 42px;
  height: 100%;
  padding: 0 15px;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #c1c1c1;
  @media (max-width: 560px) {
    margin-top: 10px;
    margin-left: 0;
  }
}
.width60 {
  max-width: 60px;
  width: 100%;
}
.width120 {
  max-width: 120px;
  width: 100%;
}
.width415 {
  max-width: 415px;
  width: 100%;
}
.frame {
  background-color: transparent;
  color: $varient;
  border-color: $varient;
  margin-top: 35px;
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 132.1%;
  &:hover,
  &:active,
  &:focus {
    background-color: #00a7e4;
    border-color: #00a7e4;
  }

  /* identical to box height, or 20px */
  letter-spacing: 0.16em;

  color: #80dfe7;
  @include breakpoint(medium) {
    margin-bottom: 30px;
  }
  span {
    border-right: solid 1px $varient;
    height: 16px;
    margin-right: 8px;
  }
}
.labeltext {
  white-space: nowrap;
  color: #777777;
  letter-spacing: 0.26em;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    font-size: 14px;
    letter-spacing: 0.2em;
  }
  @include breakpoint(medium) {
    font-size: 13px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
