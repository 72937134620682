@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.mainCard_style.card {
  background: white;
  box-shadow: inherit;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 35px 50px 20px 50px;
  color: #519bf4 !important;
  border: none;
  @include breakpoint(xxlarge) {
    padding: 30px 40px;
  }
  @include breakpoint(medium) {
    padding: 25px 30px;
  }
  @include breakpoint(small) {
    padding: 20px 15px;
  }
}
