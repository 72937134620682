@import "./assets/scss/_var.scss";
@import "./assets/scss/_mixins.scss";
@import "./assets/scss/fonts.scss";

html {
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-width: 320px;
  font-family: $font-family;
  *p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
  *box-sizing: border-box;
}
* {
  font-family: $font-family;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  padding: 0;
  padding: 0;
  list-style: none;
}
#root {
  display: flex;
  flex-direction: column;
  background: $white;
}
body.dark {
  #root {
    background: linear-gradient(116.82deg, #25242b 0%, #0e0d12 100%);
  }
}
#root .container-fluid {
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: 980px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  .container {
    @include breakpoint(xxlarge) {
      position: relative;
      z-index: 2;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontReg;
}
p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 132.1%;
  letter-spacing: 0.1em;
  color: #9a9a9a;
}

iframe {
  z-index: -0 !important;
  height: 0 !important;
}

.customRow.row {
  margin-right: calc(-0.2 * var(--bs-gutter-x));
  margin-left: calc(-0.2 * var(--bs-gutter-x));
  counter-reset: Serial;
}
.customRow.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.2);
  padding-left: calc(var(--bs-gutter-x) * 0.2);
}
.sub_head {
  color: $baseColor;
  font-size: 30px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
button.cmnBtn,
.btn-primary.cmnBtn {
  letter-spacing: 0.315em;
  text-transform: uppercase;
  padding: 6px 15px;
  min-height: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 132.1%;
  letter-spacing: 0.105em;
  background: transparent
    linear-gradient(
      90deg,
      #44dfd1 1%,
      #44d3d1 20%,
      #45b4d2 54%,
      #4682d3 99%,
      #4782d4 100%
    )
    0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 7px #00000030;
  border: 1px solid #00000000;
  border-radius: 10px;
  opacity: 1;
  box-shadow: none !important;
  color: $white;
  &:hover,
  &:active,
  &:focus {
    color: $white;
    border: 1px solid $white;
  }
}

.pagination {
  position: relative;
  z-index: 2;
}
.form__field-error {
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 132.1%;
  letter-spacing: -0.01em;
}
.layoutStyle {
  .form-control[readonly] {
    background-color: transparent;
  }

  .pagination {
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(120deg, #42dace, #4786d6) border-box;
    border: solid 1px transparent;
    justify-content: flex-start;
    width: fit-content;
    border-radius: 5px;
    li {
      border-right: solid 1px #42dace;
      border-radius: 0;
      background: transparent;
      @include transition(all ease 0.4s);
      cursor: pointer;
      &:last-child {
        border-right: none;
      }
      &:last-child,
      &:first-child {
        a {
          color: #42dace;
        }
      }

      &:hover,
      &.active,
      &:active,
      &:focus {
        a {
          color: $baseColor;
          @include transition(all ease 0.4s);
        }
      }
      a {
        color: #949494;
        &:hover {
          color: $baseColor;
        }
      }
      @media(max-width:480px){
        padding: 5px 10px;
      }
    }
  }
}

.input-group.disabled {
  background: #dddddd;
  pointer-events: none;
  * {
    background: #dddddd !important;
    color: #000;
  }
  &:hover {
    cursor: not-allowed;
  }
}

.timerText {
  font-size: 17px;
  span {
    font-size: 12px;
    font-weight: 500;
    width: 25%;
    text-align: center;
    color: $lgtGreyTxt;
  }
}

#root {
  height: 100%;
  overflow-y: scroll;
}

.layoutStyle {
  .dropdown {
    .dropdown-item:active {
      background-color: $baseColor2;
    }
    button,
    &.show > .btn-primary.dropdown-toggle {
      background-color: $baseColor2;
      border-color: $baseColor2;
    }
  }
  .nav-tabs {
    border: none;
    .nav-link {
      color: #43d5cf;
      // border-color: $baseColor2;
      border-radius: 5px;
      margin-right: 10px;
      text-transform: uppercase;
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(120deg, #42dace, #4786d6) border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 13.5px;
      line-height: 132.1%;
      letter-spacing: 0.105em;
      &:hover {
        border-color: $baseColor2;
      }
      &.active {
        border-color: $baseColor2;
        background: linear-gradient(90deg, #42dace 0%, #4786d6 100%);
        border-radius: 5px;
        color: $white;
      }
    }
  }
}
.modal {
  .modal-title {
    font-weight: 500;
  }
  .btn-primary {
    border-radius: 8px;
    padding: 6px 15px 6px;
    font-size: 14px;
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    border-color: transparent;
  }
}
.textStyle {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 132.1%;
  letter-spacing: 0.1em;
  color: #b8b8b8 !important;
}

.modal.modalComn {
  form {
    margin-bottom: 0;
  }
  .modal-footer {
    button.cmnBtn {
      display: block;
      margin: 0 auto;
      min-width: 100px;
    }
  }
  .modal-body {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 132.1%;
      letter-spacing: 0.01em;
      color: #686868;
    }
    .fldWrap {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 132.1%;
      letter-spacing: -0.005em;
      color: #c1c1c1;
    }
    .form-control {
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(
            120deg,
            rgba(66, 218, 206, 0.5),
            rgba(71, 134, 214, 0.5)
          )
          border-box;
      transition: all ease 0.3s;
      border: solid 1px transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 132.1%;
      letter-spacing: -0.005em;
      color: #44d5c9;
      text-align: center;
      &::-webkit-input-placeholder {
        /* Edge */
        color: #c1c1c1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #c1c1c1;
      }

      &::placeholder {
        color: #c1c1c1;
      }
    }
  }
}
.customRadio {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid #89ccd1;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #89ccd1;
    position: absolute;
    top: 6px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hrLine {
  background: linear-gradient(to right, #42dace, #4786d6);
  height: 1px;
}

body {
  .table {
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(120deg, rgba(66, 218, 206, 0.5), rgba(71, 134, 214, 0.5))
        border-box;
    transition: all ease 0.3s;
    border: solid 1px transparent;
    border-radius: 15px;
    .btn-primary.cmnBtn {
      font-size: 12px;
      padding: 6px 10px;
    }
    th {
      font-style: normal;
      font-weight: 500;
      background: #4786d6;
      font-size: 14px;
      line-height: 132.1%;
      color: #fff;
      letter-spacing: 0.01em;
      text-align: center;
      border: none;
      padding: 15px 15px;
      &:first-child {
        border-top-left-radius: 15px;
      }
      &:last-child {
        border-top-right-radius: 15px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 15px;
            }
            &:last-child {
              border-bottom-right-radius: 15px;
            }
          }
        }
      }
    }
    td {
      color: #686868;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 132.1%;
      letter-spacing: 0.01em;
      text-align: center;
      vertical-align: middle;
      img {
        max-width: 45px;
        border-radius: 100px;
      }
    }
  }
}

.main-heading {
  font-weight: 700;
  font-size: 29px;
  line-height: 132.1%;
  letter-spacing: 0.08em;
  color: #00a7e4;
  position: relative;
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 40px;
  }
  // &:before {
  //   content: " ";
  //   background: #0c9eda;
  //   width: 70px;
  //   height: 1px;
  //   position: absolute;
  //   bottom: 3px;
  // }
}
