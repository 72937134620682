@import "../../../assets/scss/_var.scss";

// #root .container-fluid {
//   padding-left: 0;
//   padding-right: 0;

//   &.footer_nav_main {
//   padding-top: 57px;

//   }
//   .footer_nav {
//     text-align: center;
//     height: 341px;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px -4px 14px #0000001a;
//     padding: 35px 0;
//     position: relative;
//     z-index: 1;
//     &::after {
//       content: "";
//       background: url("../../../assets/images/btwoball.svg") no-repeat;
//       display: inline-block;
//       width: 100%;
//        height: 395px;
//       background-size: 100%;
//       background-position: center center;
//       position: absolute;
//       left: -37px;
//       bottom: 140px;
//       @media (max-width: 767px) {
//         left: -15px;
//       }
//       @media (max-width: 480px) {
//         left: -5px;
//       }
//     }
//     .Logo {
//       padding: 20px;
//       border-bottom: solid 1px #878787;
//       @media (max-width: 480px) {
//         padding: 10px;
//       }
//     }

//     .media {
//       align-items: center;
//       justify-content: center;
//       padding-left: 33px !important;
//     }
//     p {
//       padding-top: 55px;
//       color: #878787;
//       @media (max-width: 480px) {
//         padding-top: 30px;
//         padding-left: 20px;
//         padding-right: 20px;
//       }
//     }
//   }
// }

footer {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  padding: 25px 65px;
  @media (max-width: 678px) {
    padding: 15px 20px;
  }

  .navSection {
    ul {
      padding-left: 0;
      flex-wrap: wrap;
      li {
        margin-right: 30px;
        color: $link;
        white-space: nowrap;
        font-size: 11px;
        font-weight: 700;
        font-family: "Montserrat";
        font-style: normal;
        line-height: 132.1%;
        letter-spacing: 0.07em;
        transition: all 0.3s ease;
        cursor: pointer;
        @media (max-width: 567px) {
          padding: 5px 0;
        }
        // &:hover {
        //   font-weight: 500;
        //   transition: all 0.3s ease;
        // }
        &:first-child {
          margin-right: 50px;
          @media (max-width: 567px) {
            flex: 0 0 100%;
          }
        }
      }
    }
  }
  .lowerSec {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 132.1%;
      letter-spacing: 0.085em;
      color: #808080;
      @media (max-width: 767px) {
        order: 2;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

.footer_nav a {
  text-decoration: none;
}
