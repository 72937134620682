@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.search_area {
  position: relative;
  .form-control {
    background: transparent;
    border-radius: 20px;
    min-height: 36px;
    @include fluid-type(320px, 1920px, 13px, 16px);
    color: $textGrey;
    @include transition(all ease 0.6s);
    border: solid 1px $borderColor;
    line-height: 18px;
    letter-spacing: -0.03em;
    width: 285px;
    @media (max-width: 767px) {
      width: 230px;
    }
    &:hover,
    &:active,
    &:focus {
      border-color: $baseColor;
      @include transition(all ease 0.6s);
    }
    &::placeholder {
      opacity: 0.6;
    }
  }
  &.textareaFld {
    .form-control {
      min-height: 52px;
      resize: inherit;

      @media (max-width: 480px) {
        margin: 10px 0;
      }
    }
  }
  .searchicon.btn {
    background: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 5px;
    &:focus {
      background: transparent;
      outline: unset;
      box-shadow: none;
    }
  }
}
