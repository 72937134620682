@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

.createNew {
  .form-control-plaintext {
    &:active {
      border: none;
    }
  }
  .QAns {
    margin-bottom: 11px;
    .form-label {
      margin-bottom: 6px;
    }
  }
  .Parchase {
    min-width: 121px;
    height: 37px;
    margin-top: 19px;
  }
  input#fileCover {
    display: none;
  }
  form {
    label {
      @include fluid-type(320px, 1920px, 14px, 15px);
      font-style: normal;
      font-weight: 500;
      line-height: 132.1%;
      letter-spacing: 0.01em;
      color: #686868;
    }
  }
  .form-control {
    // border-bottom: solid 1px rgba($color: #707070, $alpha: 0.6) !important;

    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(
          120deg,
          rgba($color: #42dace, $alpha: 0.5),
          rgba($color: #4786d6, $alpha: 0.5)
        )
        border-box;
    @include transition(all ease 0.3s);
    border: solid 1px transparent;
    padding: 12px;
    border-radius: 9px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 132.1%;
    letter-spacing: -0.005em;
    color: #212529;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #c1c1c1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #c1c1c1;
    }

    &::placeholder {
      color: #c1c1c1;
    }

    @media (max-width: 991px) {
      padding: 5px 5px 8px 0;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba($color: #707070, $alpha: 0.8);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba($color: #707070, $alpha: 0.8);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba($color: #707070, $alpha: 0.8);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba($color: #707070, $alpha: 0.8);
    }

    &:focus,
    &:hover,
    &:active {
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(
            120deg,
            rgba(71, 134, 214, 0.5),
            rgba(66, 218, 206, 0.5)
          )
          border-box;
      @include transition(all ease 0.3s);
      box-shadow: none;
    }
  }

  .row {
    .leftSide {
      @media (max-width: 767px) {
        order: 2;
      }

      .image-upload {
        border: dashed 1px #707070;
        border-radius: 26px;
        text-align: center;
        max-width: 927px;
        height: 432px;

        input {
          display: none;
        }
      }
      img {
        margin-top: 100px;
      }
      p {
        margin: 25px 0;
        @media (max-width: 767px) {
          padding: 10px;
        }
      }
      button {
        margin-bottom: 100px;
        text-align: center;
      }
      Form {
        Form.Group {
          font-size: 18px;
        }
      }
      label.form-label {
        display: flex;
      }
    }
    .rightSide {
      @media (max-width: 767px) {
        order: 1;
      }

      .plus {
        .image-upload {
          label {
            // border-radius: 100%;
            overflow: hidden;
            cursor: pointer;
          }
          input {
            display: none;
          }
        }
        p {
          @media (max-width: 767px) {
            padding: 10px;
          }
        }
        .uploadSec {
          height: 124px;
          width: 138px;
          background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(
                120deg,
                rgba(66, 218, 206, 0.5),
                rgba(71, 134, 214, 0.5)
              )
              border-box;
          border: solid 1px transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 9px;
          overflow: hidden;
          padding: 15px;

          @include breakpoint(medium) {
            height: 110px;
            width: 110px;
          }
          img {
            cursor: pointer;
          }
        }
      }
      .image-upload {
        &.SquareShape {
          label {
            display: block;
          }
          .uploadSec {
            max-width: 138px;
            width: 100%;
            height: 124px;
            border-radius: 9px;
            padding: 15px;
            border-radius: 9px;
            background: linear-gradient(#ffffff, #ffffff) padding-box,
              linear-gradient(
                  120deg,
                  rgba(66, 218, 206, 0.5),
                  rgba(71, 134, 214, 0.5)
                )
                border-box;
            border: solid 1px transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            cursor: pointer;
            @include breakpoint(medium) {
              height: 130px;
            }
            img {
              cursor: pointer;
            }
          }
        }
        input {
          display: none;
        }
      }
      .bgImage {
        margin-top: 40px;
        @media (max-width: 991px) {
          margin-top: 20px;
        }
        p {
          @media (max-width: 767px) {
            padding-top: 20px;
          }
        }
      }
      .coverImage {
        border-radius: 26px;
        margin-top: 40px;
        @media (max-width: 991px) {
          margin-top: 20px;
        }
        @media (max-width: 767px) {
          margin-left: 0 !important;
        }
        .image-upload > input {
          display: none;
        }
      }
    }
  }
}
.Image {
  display: flex;
  justify-content: center;
}
.Creat {
  position: relative;
  z-index: 2;
  .row {
    @media (max-width: 480px) {
      margin: 0;
      --bs-gutter-x: 0;
    }
    @media (max-width: 567px) {
      --bs-gutter-x: 0 !important;
      margin: 0;
    }
  }
}
.orSec {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 132.1%;
  letter-spacing: 0.105em;
  color: #9a9a9a;
}
