@import "../../assets/scss/_var.scss";
@import "../../assets/scss/_mixins.scss";

.coll-new {
  .imageSec {
    position: relative;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    border-radius: 16px;
    overflow: hidden;
    @include transition(all ease 0.3s);
    &:hover {
      @include transition(all ease 0.3s);

      .hover-effect {
        opacity: 1;
        height: 100%;
        width: 100%;
        @include transition(all ease 0.3s);
        .publishBtn {
          height: auto;
        }
      }
    }
    .hover-effect {
      opacity: 0;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      @include transition(all ease 0.3s);

      .publishBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0;
        white-space: nowrap;
      }
    }
    img {
      height: 150px;
      object-fit: cover;
    }
  }
  h5 {
    margin-top: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 132.1%;
    letter-spacing: 0.005em;
    color: #666666;
  }
}
