@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

.CollapseBg {
  .accordion-button {
    background-color: transparent;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #454545;
    @media (max-width: 480px) {
      font-size: 14px !important;
    }
    &::after {
      background-image: url("../../../../assets/images/accodianArrow.svg");
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .accordion-body {
    @media (max-width: 480px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    p {
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
  .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px;
    border-image: linear-gradient(45deg, #5a54ea, #01a9f2) 1;
    padding: 22px 0;
    @media (max-width: 567px) {
      padding: 10px 0;
    }
    &:first-child {
      border-top: solid 1px;
      border-image: linear-gradient(45deg, #5a54ea, #01a9f2) 1;
    }
  }
  .fQ {
    ul.pagination {
      margin-left: auto;
    }
  }
}
.maintitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.075em;
  color: #6380fe;
  margin-bottom: 18px;
  @media (max-width: 992px) {
    font-size: 28px;
    margin-bottom: 0px !important;
  }
  @media (max-width: 567px) {
    font-size: 18px;
  }
}
