$fontLig: "NoirPro";
$fontReg: "NoirPro";
$font-family: "Montserrat", sans-serif;

$link: #809af9;
$textHeighLight: #7448ff;
$textBlack: #000000;
$black: #000000;
$textColor: #25242b;
$baseColor2: #45b3d2;
$baseColor: #3985c4;
$lightColor: #79b8f4;
$btnTextColor: #dce4ff;
$btnlight: #b29aff;
$baseColor: #519bf4;
$lgtbBaseColor: #b29aff;
$greybg: #25242b;
$textGrey: #25242b;
$white: #ffffff;
$borderColor: #b29aff;
$borderColorLight: #dce4ff;
$lgtGreyTxt: #878787;
$footerNavBg: #fefefe;
$labelColor: #3f3f3f;
$heading: #6380fe;
$textBaseColor: #4a91e9;
$boxGradient: linear-gradient(
  0deg,
  rgba(68, 174, 251, 1) 10%,
  rgba(89, 85, 241, 1) 100%
);
$btnGradient: linear-gradient(
  90deg,
  rgba(68, 223, 209, 1) 6%,
  rgba(71, 131, 212, 1) 90%
);
$textColorNew: #777777;
$varient: #7fdfe7;
$banner: linear-gradient(89.78deg, #5859eb 0.19%, #43adf5 99.83%);
