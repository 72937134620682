@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.header {
  padding: 0 50px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  background: $white;
  @include breakpoint(medium) {
    padding: 0 25px;
  }
  .navbar {
    padding: 4px 0;
  }

  .navbar-collapse {
    justify-content: flex-end;
    @include breakpoint(small) {
      margin-top: 15px;
      background: #efefef;
      padding: 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .cmnBtn {
        text-align: center;
      }
      .media {
        justify-content: center;
        @media (max-width: 567px) {
          margin-top: 10px;
        }
      }
    }
  }
  .navbar-brand {
    img {
      height: 47px;
      @media (max-width: 576px) {
        max-width: 150px;
        height: 50px;
      }
      @media (max-width: 320px) {
        max-width: 100px;
        height: 30px;
      }
    }
  }
  // ..................../
  .navbar-nav {
    align-items: center;
    @media (max-width: 576px) {
    }
    a {
      text-decoration: none;
      color: white;
      padding: 12px 25px;
      margin-right: 25px;
      background: transparent
        linear-gradient(
          90deg,
          #44dfd1 1%,
          #44d3d1 20%,
          #45b4d2 54%,
          #4682d3 99%,
          #4782d4 100%
        )
        0% 0% no-repeat padding-box;
      box-shadow: 4px 4px 7px #00000030;
      border-radius: 22px;
      @include breakpoint(small) {
        margin-right: 0;
        margin-bottom: 15px !important;
      }
      @media (max-width: 576px) {
        padding: 10px 20px;
      }
    }
    .cmnBtn {
      margin-right: 15px;
      height: 23px;
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 132.1%;
      letter-spacing: 0.46em;
      color: #ffffff;
      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.gearicon {
  position: relative;
  top: 1px;
  cursor: pointer;
  width: 23px;
  @media (max-width: 767px) {
    top: -10px;
  }
}
