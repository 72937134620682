.accessModal {
  min-height: 40vh;
  justify-content: center;
  align-items: center;
  p {
      font-size:30px;
  }
}

.modal-backdrop.show {
    opacity: .8!important;
}

