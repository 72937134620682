@import "../../../assets/scss/_var.scss";
.slider-sec {
  @media (max-width: 992px) {
    margin-top: 20px;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none;
  }
  .carousel-control {
    border-radius: 10px;
  }

  .carousel-indicators li {
    border: solid 1px #01a9f2;
  }

  .carousel-indicators [data-bs-target] {
    height: 15px;
    width: 15px;
    background-color: #01a9f2;
  }
  .carousel-indicators .active {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 1px #01a9f2;
    background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%) !important;
  }
  .carousel-indicators {
    margin: 0 auto;
    bottom: -36px;
    button {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: solid 1px #01a9f2;
      background: transparent !important;
      margin: 6px;
    }
  }
  .carousel-caption {
    min-height: 120px;
    text-align: start;
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px 18px;
    background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);
    .discription {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 109.9%;
      letter-spacing: -0.005em;
    }
    .concoin,
    a {
      color: $white;
      font-weight: 500;
      font-size: 16px;
      line-height: 124.9%;
      letter-spacing: -0.005em;
      text-decoration: none;
      margin-top: 8px;
    }
  }
  .imgWrap {
    background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);
    border-radius: 20px;
    max-height: 600px;
    height: 100%;
    .astro {
      height: 480px;
    }
  }
  .carousel-item {
    max-width: 486px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    min-height: 595px;
    background: linear-gradient(90deg, #5a54ea 0%, #01a9f2 72.47%);
    @media (max-width: 420px) {
      min-height: 505px;
    }
    @media (max-width: 1200px) {
      min-height: 505px;
    }
  }
}
