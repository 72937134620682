.hasLoader {
  overflow: hidden;
}

.loaderOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 1056 !important;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}
