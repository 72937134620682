@import "../../../../assets/scss/_mixins.scss";
@import "../../../../assets/scss/_var.scss";

.information {
  .row {
    .Right.col {
      padding: 50px 100px;
      @media (max-width: 991px) {
        padding: 10px 20px;
      }
    }
    .col {
      h4 {
        background: -webkit-linear-gradient(
            77deg,
            #3ec7fd 0%,
            #40bbfb 14%,
            #489cf8 39%,
            #536af3 72%,
            #5f3cee 100%
          )
          0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 15px;
        font-size: 35px;
        text-transform: capitalize;
      }
      p {
        font-size: 16px;
        text-transform: capitalize;
      }
      .input {
        display: flex;
        align-items: center;
        border-radius: 30px;
        padding: 25px 25px 25px 0;

        .input-group-text {
          border: none;
          background-color: transparent;
          font-size: 40px;
          padding: 0 10px;
        }
        .input-group {
          width: 100%;
          margin-bottom: 0;
          margin-right: 20px;
          font-size: 5px;
          border: 2px solid #0000;
          border-radius: 10px;
          background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(120deg, #42dace, #4786d6) border-box;
          box-shadow: 0px 0px 14px #00000017;
          height: 100%;
          max-width: 140px;
          min-height: 60px;
          display: flex;
          justify-content: space-around;
        }
        .span {
          background-color: $white;
          border: none;
          box-shadow: black;
        }
        .form-control {
          font-size: 20px;
          border: none;
          text-align: center;
        }
      }
      .Parchase {
        min-width: 150px;
        min-height: 60px;
        border: none;
      }
    }
    h2 {
      text-align: center;
      margin-top: 2px;
      padding-right: 100px;
    }
  }
}
.information {
  padding: 109px 0 60px;
  position: relative;
  &.moreInfo {
    text-align: center;
    &::before {
      content: ("");
      background: url(../../../../assets/images/textinformation.png) no-repeat;
      width: 100%;
      height: 175px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      background-position: center center;
      background-size: contain;
    }
    h3 {
      text-align: center;
      font-size: 30px;
      position: relative;
      border-bottom: solid 2px #3f3f3f;
      display: inline-block;
    }
  }

  .SupplyMain {
    margin: 51px 0;
    .Supply {
      text-align: center;
      max-width: 1088px;
      margin: 0 auto;
      box-shadow: 0px 0px 14px #0000003b;
      line-height: 4;
      list-style: none;
      padding: 35px;
      h4 {
        background: -webkit-linear-gradient(
            77deg,
            #3ec7fd 0%,
            #40bbfb 14%,
            #489cf8 39%,
            #536af3 72%,
            #5f3cee 100%
          )
          0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  @media (max-width: 480px) {
    margin-left: -25px;
  }
  @media (max-width: 320px) {
    margin-left: -25px;
  }
}

.information .Right.col h4 {
  word-break: break-all;
}
