@import "../../../../assets/scss/_var.scss";

#root .container-fluid.h_bannerStyle {
  position: relative;
  z-index: 1;
  @media (max-width: 980px) {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }
  .hb_title_Style {
    font-size: 110px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    span {
      display: block;
      line-height: 65px;
    }
    @media (max-width: 1206px) {
      font-size: 80px;
      line-height: 89px;
    }

    @media (max-width: 990px) {
      font-size: 60px;
      line-height: 72px;
      padding-left: 20px;
      text-align: left;
    }
  }
  .hb_text_Style {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: -0.03em;
    color: $textColor;
    margin-bottom: 35px;

    @media (max-width: 1206px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
