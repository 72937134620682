@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

// ***radio custom ***//
.radioCustom {
  padding-top: 14px;
  .form-check {
    padding-left: 0;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: $lgtGreyTxt;
    margin-bottom: 0;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    color: $lgtGreyTxt;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $borderColor;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 16px;
    height: 16px;
    background: $baseColor;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
// ***radio custom style end ***//

// ***checkbox custom ***//
.checkBoxCustom {
  padding-top: 14px;
  .form-check {
    padding-left: 0;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: $lgtGreyTxt;
    margin-bottom: 0;
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    color: $lgtGreyTxt;
  }
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $borderColor;
    border-radius: 100%;
    background: #fff;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: "";
    width: 16px;
    height: 16px;
    background: $baseColor;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.check_button {
  padding-top: 0;
  .form-check {
    border: solid 1px #b29aff;
    border-radius: 20px;
    padding: 5px;
    margin-bottom: 0;
  }
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    padding-left: 8px;
    padding-right: 50px;
    width: 100%;
    @include fluid-type(320px, 1920px, 13px, 16px);
    @media (max-width: 767px) {
      padding-right: 35px;
    }
  }
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    right: 0;
    left: auto;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    top: 4px;
    left: auto;
    right: 4px;
  }
}

body.dark{
  .check_button {
    .form-check {
      border-color: $borderColorLight;
    }
  }
}
