.attributesModal {
  min-height: 25vh;
  justify-content: center;
  align-items: center;
  p {
    font-size: 30px;
  }
}

.modal-backdrop.show {
  opacity: 0.8 !important;
}
