@import "../../../assets/scss/_var.scss";
Form {
  // margin-bottom: 20px;
  .label {
    white-space: nowrap;
    // color: $textColorNew;
    letter-spacing: 0.26em;
    font-size: 15px;
    font-weight: 700;
    margin-right: 25px;
    margin-bottom: 0;
  }
  .inputSec {
    border: 1px solid #0000;
    border-radius: 10px;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(
          120deg,
          rgba($color: #42dace, $alpha: 0.5),
          rgba($color: #4786d6, $alpha: 0.5)
        )
        border-box;
    min-height: 45px;
    height: 100%;
    // text-align: center;
  }
}

.Sec__second {
  form {
    margin-bottom: 5px;
  }
  .error {
    color: red;
  }
}
