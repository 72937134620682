.copyClipboardIcon {
    position: relative;
    cursor: pointer;
    top:-10px;
    left:10px;
}

.copiedText {
    position: relative;
    left:20px;
    top:-8px;
}