@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.btn-primary.simple_btn {
  background: #7448ff;
  border-radius: 20px;
  max-height: 36px;
  padding: 17px 20px;
  min-width: 135px;
  width: auto;
  text-align: center;
  border: none;
  font-weight: 200 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition(all ease-in-out 0.3s);
  @include fluid-type(320px, 1920px, 14px, 16px);
  letter-spacing: -0.03em;
  color: $white;
  &:hover {
    background: #7448ff;
    border: none;
    @include transition(all ease-in-out 0.3s);
  }
}
.btn-primary.simple_btn.black_btn {
  background: #25242b;
}
