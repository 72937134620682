.terms_container {
    position: relative;
    margin-left:15px;
    input[type="checkbox"] {
        position: absolute;
        top:3px;
        left:-10px;
    }
    span {
        margin-left:10px;
    }
}