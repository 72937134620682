@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

.settingPage {
  padding: 50px;  
  .container {
    -webkit-box-shadow: 0px 0px 35px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 35px 2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 35px 2px rgba(0,0,0,0.15);
    padding: 50px;
    border-radius: 25px;
  }
}
