.cardBlocks {
  .row {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .card_link{
    cursor: pointer;
  }
  .cards {
    height:100%;
    position: relative;
    flex: 0 1 24%;
    border-radius: 10px;
    border: none;
    margin-bottom: 20px;
    @media(max-width:767px){
      max-width: 300px;
      width: 100%;
      margin: 10px auto;
     }
    .mainImg {
      width: 100%;
      height: 306px;
      @media(max-width:767px){
        height: auto;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // position: absolute; /* Position the background text */
      bottom: 0; /* At the bottom. Use top:0 to append it to the top */
      width: 100%; /* Full width */
      padding: 20px;
      background: #ffffff;
      box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .coin_image {
        width:50px;
        height: 50px;
        object-fit: contain;
        img {
          width:100%;
          height:auto;
        }
      }
      .card-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.07em;
        color: #7c7c7c;
        @media (max-width: 480px) {
          font-size: 12px;
          letter-spacing: 0.01em;
        }
      }
      .card-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: #969694;
        @media (max-width: 480px) {
          letter-spacing: 0.01em;
        }
      }
    }
  }
}
