@import "../../../assets/scss/_var.scss";
@import "../../../assets/scss/_mixins.scss";

.btn-primary.commonBtn {
  position: relative;
  background: linear-gradient(90deg, #7448ff 0%, #dce4ff 100%);
  border-radius: 60px;
  max-height: 90px;
  padding: 22px 25px;
  font-size: 30px;
  min-width: 340px;
  border: none;
  font-family: $fontReg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include transition(all ease-in-out 0.3s);
  @include fluid-type(320px, 1920px, 30px, 30px);
  letter-spacing: -0.03em;
  color: $btnTextColor;
  @media (max-width: 980px) {
    font-size: 24px;
  }
  @include breakpoint(medium) {
    min-width: 100%;
  }
  svg {
    @include transition(all ease-in-out 0.3s);
    position: absolute;
    right: 24px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  &:hover {
    background: linear-gradient(90deg, #7448ff 0%, #dce4ff 100%);
    border: none;
    @include transition(all ease-in-out 0.3s);

    svg {
      margin-right: -4px;
      @include transition(all ease-in-out 0.3s);
    }
  }
}

.btn-primary.coinButton {
  background: $baseColor;
  border-radius: 20px;
  min-width: 147px;
  padding: 5px 6px;
  border-color: $baseColor;
  text-align: left;
  &:hover,
  &:active,
  &:focus {
    background: $baseColor;
    border-color: $baseColor;
  }
  img {
    margin-right: 5px;
  }
}

body.dark{
  .btn-primary.commonBtn {
      background: linear-gradient(90deg, #7448FF 0%, #25242B 100%);
  }
}
