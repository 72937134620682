@import "../../../../assets/scss/_var.scss";
@import "../../../../assets/scss/_mixins.scss";

#root .Collection-Page .container-fluid.layoutStyle {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.Collection-Page {
  position: relative;
  .vertical-center {
    .btnBanner {
      background-color: #ffffff;
      border: solid 1px #5858eb51;
      border-radius: 10px;
      padding: 14px 42px;
      color: #5859eb;
      display: inline-block;
      @include transition(all ease 0.4s);
      box-shadow: 10px 10px 20px rgb(0 0 0 / 10%);

      &:hover {
        @include transition(all ease 0.4s);
        background-color: rgba($color: $white, $alpha: 1);
        color: #5859eb;
        cursor: pointer;
        border-color: #5859eb;
      }
    }
    p {
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0.085em;
      margin-top: 10px;
      white-space: nowrap;
      text-shadow: 0px 0px 0px rgb(88 89 235 / 66%);
    }
  }

  .banner {
    background-image: url("../../../../assets/images/newBgBannner.svg");
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    min-height: 500px;
    height: 100%;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    @include breakpoint(large) {
      margin-bottom: 60px;
    }
    .vertical-center {
      position: relative;
      .bannerContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        padding: 30px 15px;
      }
      p {
        color: rgba(255, 255, 255, 0.6);
        letter-spacing: 0.085em;
        margin-top: 10px;
        white-space: nowrap;
        text-shadow: 0px 0px 0px rgb(88 89 235 / 66%);
      }
    }
  }
  .four {
    background: rgba(0, 0, 0, 0.6);
    .imageBanner {
      background-image: url("../../../../assets/images/newBgBannner.svg");
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      min-height: 500px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 1;
    }
  }

  .offcanvas {
    ul {
      margin-top: 20px;
      padding-left: 0;
      li {
        margin-bottom: 25px;
        .colorPalet {
          display: flex;
          align-items: center;
        }
        .trangle {
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          background: #0cbaa6;
          width: 20px;
          height: 20px;
          display: inherit;
          margin-right: 8px;
          &.pink {
            background: #ee959e;
          }
          &.yellow {
            background: #f8f1ae;
          }
          &.orange {
            background: #ffb067;
          }
          &.blue {
            background: #58cced;
          }
        }
      }
    }
  }
}

// customization features

.information.customize {
  input.form-control {
    background: none !important;
  }
}
.information.customize .row .col h4 {
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: inherit;
}

.information.customize .SupplyMain .Supply h4 {
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: inherit;
}

.container-fluid .footer_nav.customize {
  background: none !important;
}

.information.customize .row .col .input .input-group-text {
  background: none !important;
}
.minitingInput {
  margin-bottom: 0;
  .input-group {
    input {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 132.1%;
      letter-spacing: -0.04em;
      color: #89ccd1;
    }
  }
  .input-group.disabled * {
    background: transparent !important;
  }
}

.minusplus {
  cursor: pointer;
}
.collectionImg {
  // background: #eee;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

// fetching animation

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: transparent
    linear-gradient(
      90deg,
      #44dfd1 1%,
      #44d3d1 20%,
      #45b4d2 54%,
      #4682d3 99%,
      #4782d4 100%
    )
    0% 0% no-repeat padding-box;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.five {
  hr {
    color: #4786d6;
    margin-bottom: 37px;
    @include breakpoint(large) {
      margin-bottom: 25px;
    }
  }
}

.social_media_wrapper {
  display: flex;
  align-items: center;
  .five {
    width: 100%;
  }
  ul {
    position: relative;
    right: 18%;
  }
}
