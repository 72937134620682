@import "../../../assets/scss/_var.scss";

.sidebar {
  padding: 0 15px 20px 15px;
  width: 250px;
  height: 100vh;
  @media (max-width: 991px) {
    display: none;
  }
}
.linkList {
  padding-left: 0;
  border-top: 1px solid #0dcaf02e;
  padding-top: 25px;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    display: none !important;
  }
  li {
    margin: 10px 0;
    list-style: none;
    a {
      font-size: 18px;
      padding: 5px 10px 5px 21px;
        color: $textGrey;
      display: block;
      position: relative;
      transition: all ease 0.6s;
      -webkit-transition: all ease 0.6s;
      font-weight: 500;
      text-decoration: none;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        transition: all ease 0.6s;
        -webkit-transition: all ease 0.6s;
        height: 100%;
        content: "";
        // background: $baseColor;
        display: none;
      }
      &:hover,
      &.active {
        color: $baseColor;
        transition: all ease 0.6s;
        -webkit-transition: all ease 0.6s;
        text-decoration: none;
        &::before {
          display: block;
        }
      }
    }
  }
}
