.pagination {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  list-style: none;
}

ul.pagination li {
  background: grey;
  padding: 10px 18px;
  border-radius: 50%;
}

ul.pagination li a {
  color: #fff;
  text-decoration: none;
}
.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 132.1%;
  margin-bottom: 12px;
  letter-spacing: 0.02em;

  color: #00a7e4;
  
}

@media(max-width:1080px) {
.title {
  font-size: 26px;
}
}
