.imageUpload {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(120deg, rgba(66, 218, 206, 0.5), rgba(71, 134, 214, 0.5))
      border-box;
  border: solid 1px transparent;
  border-radius: 5px;
  text-align: center;
  max-width: 720px;
  height: 297px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 15px;
  }
  input {
    display: none;
  }
  p {
    color: #5fc5bf;
  }
}
